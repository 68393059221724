import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Header, Modal, ModalThatSlides, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconCirclePlus, SvgIconDown, SvgIconEdit, SvgIconModalDelete, SvgIconTrash, SvgIconUp } from '../../svgs';
import colors from '../../utils/colors';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';
import SubRow from './components/SubRow';

function Products() {
    const { language } = useContext(store);
    const history = useHistory();
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const areaCreateFormRef = useRef(null);

    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalCreateParent, setModalCreateParent] = useState(null);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);

    const [isModalAreasOpen, setIsModalAreasOpen] = useState(false);
    const [modalAreaUpdateIsOpen, setModalAreaUpdateIsOpen] = useState(null);
    const [modalAreaDeleteIsOpen, setModalAreaDeleteIsOpen] = useState(null);

    const [rowsOpened, setRowsOpened] = useState([]);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });
    const [areas, setAreas] = useState([]);

    const [queryProducts, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String) {
                products(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            slug
                            subProducts {
                                id
                                name
                                slug
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.products) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.products.edges] : data.products.edges,
                        pageInfo: data.products.pageInfo,
                        totalCount: data.products.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationProductCreate, { loading: loadingProductCreate }] = useMutation(
        gql`
            mutation productCreate($name: String!, $slug: String!, $parent: ID) {
                productCreate(name: $name, slug: $slug, parent: $parent) {
                    id
                    name
                    slug
                    subProducts {
                        id
                        name
                        slug
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productCreate) {
                    history.push(`/editor/product/${data.productCreate.id}`);
                }
            }
        }
    );

    const [mutationProductDelete, { loading: loadingProductDelete }] = useMutation(
        gql`
            mutation productDelete($id: ID!) {
                productDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productDelete) {
                    let index = -1;
                    if (modalDeleteIsOpen.includes("sub")) {
                        const ids = modalDeleteIsOpen.split("sub");
                        index = result.edges.findIndex((edge) => edge.cursor == ids[0]);
                        if (index !== -1) {
                            const innerIndex = result.edges[index].node.subProducts.findIndex((sub) => sub.id == ids[1]);
                            if (innerIndex !== -1) {
                                result.edges[index].node.subProducts.splice(innerIndex, 1);
                                setModalDeleteIsOpen(null);
                                setResult([...data]);
                            }
                        }
                    } else {
                        index = result.edges.findIndex((edge) => edge.cursor == data.productDelete.id);
                        if (index !== -1) {
                            result.edges.splice(index, 1);
                            setModalDeleteIsOpen(null);
                            setResult([...data]);
                        }
                    }
                }
            }
        }
    );

    const [mutationProductMove] = useMutation(
        gql`
            mutation productMove($id: ID!, $direction: ProductMoveDirection!) {
                productMove(id: $id, direction: $direction)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productMove) {
                    requestData({
                        first: result.edges.length
                    });
                }
            }
        }
    );

    const [queryAreas, { loading: loadingAreas }] = useLazyQuery(
        gql`
            query {
                areas {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areas) {
                    setAreas(data.areas);
                }
            }
        }
    );

    const [mutationAreaCreate, { loading: loadingAreaCreate }] = useMutation(
        gql`
            mutation areaCreate($name: String!) {
                areaCreate(name: $name) {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areaCreate) {
                    areaCreateFormRef.current.reset();
                    queryAreas();
                }
            }
        }
    );

    const [mutationAreaUpdate, { loading: loadingAreaUpdate }] = useMutation(
        gql`
            mutation areaUpdate($id: ID!, $name: String) {
                areaUpdate(id: $id, name: $name) {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areaUpdate) {
                    setModalAreaUpdateIsOpen(null);
                    queryAreas();
                }
            }
        }
    );

    const [mutationAreaDelete, { loading: loadingAreaDelete }] = useMutation(
        gql`
            mutation areaDelete($id: ID!) {
                areaDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areaDelete) {
                    const index = areas.findIndex((area) => area.id === data.areaDelete.id);
                    if (index !== -1) {
                        areas.splice(index, 1);
                        setModalAreaDeleteIsOpen(null);
                        setAreas([...areas]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryProducts({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
        queryAreas();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search]);

    useEffect(() => {
        if (modalCreateParent) {
            setModalCreateIsOpen(true);
        }
    }, [modalCreateParent]);

    useEffect(() => {
        if (!modalCreateIsOpen) {
            setModalCreateParent(null);
        }
    }, [modalCreateIsOpen]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={
                        <Block row>
                            <Block pointer row middle center pl={48} pr={48} color="greyLightest" onClick={() => setIsModalAreasOpen(true)}>
                                <Text size={14} height={28}>Manage areas</Text>
                            </Block>
                            <Button style={{ minWidth: 309 }} text="Add Product" onClick={() => setModalCreateIsOpen(true)} />
                        </Block>
                    }
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" }
                        ]}
                        rows={result.edges.map((edge) => {
                            const rowOpenedIndex = rowsOpened.findIndex((rowOpened) => rowOpened == edge.cursor);

                            return {
                                ...edge.node,
                                subRowsOpen: !!rowsOpened.find((rowOpened) => rowOpened == edge.cursor),
                                subRows: edge.node.subProducts && edge.node.subProducts.map((product) => (
                                    <SubRow
                                        key={product.id}
                                        title={product.name}
                                        actions={<>
                                            <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => history.push(`/editor/product/${product.id}`)} />
                                            <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(`${edge.cursor}sub${product.id}`)} />
                                        </>}
                                    />
                                )),
                                actions: <>
                                    <ButtonAction icon={<SvgIconCirclePlus />} text="Add Subproduct" onClick={() => setModalCreateParent(edge.cursor)} />
                                    {(edge.node.subProducts && edge.node.subProducts.length > 0) && <ButtonAction icon={rowOpenedIndex === -1 ? <SvgIconDown /> : <SvgIconUp />} text="Subproducts" onClick={() => {
                                        if (rowOpenedIndex === -1) {
                                            rowsOpened.push(edge.cursor);
                                        } else {
                                            rowsOpened.splice(rowOpenedIndex, 1);
                                        }
                                        setRowsOpened([...rowsOpened]);
                                    }} />}
                                    <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => history.push(`/editor/product/${edge.cursor}`)} />
                                    <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} />
                                    <Block width={52} height={52} style={{ minWidth: "52px", borderLeft: `1px solid ${colors.greyAlmostWhite}` }}>
                                        <Block pointer height="100%" center middle onClick={() => mutationProductMove({ variables: { id: edge.cursor, direction: "UP" } })}>
                                            <SvgIconUp />
                                        </Block>
                                        <Block width="100%" height={1} color="greyAlmostWhite" />
                                        <Block pointer height="100%" center middle onClick={() => mutationProductMove({ variables: { id: edge.cursor, direction: "DOWN" } })}>
                                            <SvgIconDown />
                                        </Block>
                                    </Block>
                                </>
                            }
                        })}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create Product"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Product</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    slug: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationProductCreate({ variables: { ...values, parent: modalCreateParent } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <TextInput name="slug" label="Slug" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.slug} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingProductCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Product"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this product?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this product.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingProductDelete} onClick={() => {
                        if (modalDeleteIsOpen.includes("sub")) {
                            const id = modalDeleteIsOpen.split("sub")[1];
                            mutationProductDelete({ variables: { id } });
                        } else {
                            mutationProductDelete({ variables: { id: modalDeleteIsOpen } });
                        }
                    }} />
                </Block>
            </Modal>
            <ModalThatSlides title="Manage Areas" width="820px" isOpen={isModalAreasOpen} onRequestClose={() => setIsModalAreasOpen(false)}>
                <Block>
                    <Formik
                        enableReinitialize
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationAreaCreate({
                                variables: {
                                    name: values.name
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form ref={areaCreateFormRef} onSubmit={handleSubmit} autoComplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>New Area</Text>
                                        <Block row mt={12}>
                                            <TextInput label="Name" name="name" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            <Button type="submit" text="Submit" loading={loadingAreaCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
                <Block>
                    <Table
                        loading={loadingAreas}
                        columns={[
                            { label: "Name", key: "name" },
                        ]}
                        rows={areas.map((area) => ({ ...area, actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalAreaUpdateIsOpen(area)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalAreaDeleteIsOpen(area.id)} /></> }))}
                        hasMore={false}
                    />
                </Block>
            </ModalThatSlides>
            <Modal
                title="Update Area"
                isOpen={!!modalAreaUpdateIsOpen}
                onRequestClose={() => setModalAreaUpdateIsOpen(null)}
            >
                <Text size={14} height={20}>Area</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={modalAreaUpdateIsOpen}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationAreaUpdate({ variables: { id: modalAreaUpdateIsOpen.id, name: values.name } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingAreaUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Area"
                isOpen={!!modalAreaDeleteIsOpen}
                onRequestClose={() => setModalAreaDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this area?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this area.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingAreaDelete} onClick={() => mutationAreaDelete({ variables: { id: modalAreaDeleteIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Products;
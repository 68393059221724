import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentEditor, Header, ModalThatSlides } from '../../components';
import { Block, Button, FileInput, SelectInput, Text, TextInput } from '../../components/layout';
import { SvgIconArrowLeft, SvgIconMetatags, SvgIconSettings } from '../../svgs';
import toast from '../../utils/toast';
import validateFormSchema from '../../utils/validateFormSchema';

function ProductEdit() {
    const history = useHistory();
    let { productId } = useParams();
    const [result, setResult] = useState(null);
    const [areas, setAreas] = useState(null);
    const [isModalMetatagsOpen, setIsModalMetatagsOpen] = useState(false);

    let submitForm = null;

    const [queryProduct, { loading }] = useLazyQuery(
        gql`
            query product($id: ID!) {
                product(id: $id) {
                    id
                    slug
                    name
                    thumbnail
                    anteTitle
                    description
                    fullDescription
                    functionalities
                    integrations
                    imagesSlider
                    status
                    metatags
                    areas {
                        id
                        name
                    }
                }
                areas {
                    id
                    name
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.product) {
                    setResult({
                        ...data.product,
                        areas: data.product.areas.map((area) => (area.id))
                    });
                    setAreas(data.areas.map((area) => ({ value: area.id, label: area.name })));
                }
            }
        }
    );

    const [mutationProductUpdate, { loading: loadingProductUpdate }] = useMutation(
        gql`
            mutation productUpdate($id: ID!, $anteTitle: String, $description: String, $fullDescription: Json, $functionalities: Json, $integrations: Json, $imagesSlider: Json) {
                productUpdate(id: $id, anteTitle: $anteTitle, description: $description, fullDescription: $fullDescription, functionalities: $functionalities, integrations: $integrations, imagesSlider: $imagesSlider) {
                    id
                    anteTitle
                    description
                    fullDescription
                    functionalities
                    integrations
                    imagesSlider
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productUpdate) {
                    setResult({
                        ...result,
                        ...data.productUpdate
                    });
                    toast.success(`Saved`);
                }
            }
        }
    );

    const [mutationProductUpdateGeneric, { loading: loadingProductUpdateGeneric }] = useMutation(
        gql`
            mutation productUpdate($id: ID!, $slug: String, $name: String, $thumbnail: Json, $status: ProductStatus, $areas: [ID!]) {
                productUpdate(id: $id, slug: $slug, name: $name, thumbnail: $thumbnail, status: $status, areas: $areas) {
                    id
                    thumbnail
                    slug
                    name
                    status
                    areas {
                        id
                        name
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productUpdate) {
                    setResult({ ...result, ...data.productUpdate, areas: data.productUpdate.areas.map((area) => (area.id)) });
                }
            }
        }
    );

    const [mutationProductUpdateMetatags, { loading: loadingProductUpdateMetatags }] = useMutation(
        gql`
            mutation productUpdate($id: ID!, $metatags: Json) {
                productUpdate(id: $id, metatags: $metatags) {
                    id
                    metatags
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productUpdate) {
                    setResult({ ...result, ...data.productUpdate });
                }
            }
        }
    );

    useEffect(() => {
        queryProduct({ variables: { id: productId } });
    }, []);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block height="100%" row>
                            <Block width={52} height={52} center middle pointer onClick={() => history.push("/dashboard/products")}>
                                <SvgIconArrowLeft />
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                            <Block width={229} height="100%" middle>
                                {result && <Text size={18} height={32} ml={24}>{result.name}</Text>}
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Block pointer row middle center pl={48} pr={48} color="greyLightest" onClick={() => setIsModalMetatagsOpen(true)}>
                                <SvgIconSettings />
                                <Text size={14} height={28} ml={8}>Settings</Text>
                            </Block>
                            <Button style={{ minWidth: 309 }} text="Save" onClick={() => submitForm()} loading={loadingProductUpdate} />
                        </Block>
                    }
                />
                <Block flex pl={230} pt={64} pb={64} style={{ overflow: "auto" }}>
                    {result && <ContentEditor
                        onSubmit={(values) => mutationProductUpdate({
                            variables: {
                                id: productId,
                                anteTitle: values.general.anteTitle,
                                description: values.general.description,
                                fullDescription: values.fullDescription,
                                functionalities: values.functionalities,
                                integrations: values.integrations,
                                imagesSlider: values.imagesSlider,
                            }
                        })}
                        bindSubmitForm={(method) => submitForm = method}
                        values={{ ...result, general: { anteTitle: result.anteTitle, description: result.description }, }}
                        structure={{
                            sections: [
                                {
                                    key: "general",
                                    title: "General",
                                    properties: {
                                        fields: [
                                            {
                                                key: "anteTitle",
                                                title: "Ante title",
                                                type: "TextLine",
                                                properties: {
                                                    required: true
                                                }
                                            },
                                            {
                                                key: "description",
                                                title: "Description",
                                                type: "TextMultiLine",
                                                properties: {
                                                    required: true
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    key: "fullDescription",
                                    title: "Description Tab",
                                    properties: {
                                        fields: [
                                            {
                                                key: "title",
                                                title: "Title",
                                                type: "TextLine"
                                            },
                                            {
                                                key: "description",
                                                title: "Description",
                                                type: "TextMultiLine"
                                            },
                                            {
                                                key: "items",
                                                title: "Items",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            key: "title",
                                                            title: "Title",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: true
                                                            }
                                                        },
                                                        {
                                                            key: "subitems",
                                                            title: "SubItems",
                                                            type: "List",
                                                            properties: {
                                                                fields: [
                                                                    {
                                                                        key: "text",
                                                                        title: "Text",
                                                                        type: "TextLine",
                                                                        properties: {
                                                                            required: true
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    key: "functionalities",
                                    title: "Functionalities Tab",
                                    properties: {
                                        fields: [
                                            {
                                                key: "items",
                                                title: "Items",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            key: "title",
                                                            title: "Title",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: true
                                                            }
                                                        },
                                                        {
                                                            key: "subitems",
                                                            title: "SubItems",
                                                            type: "List",
                                                            properties: {
                                                                fields: [
                                                                    {
                                                                        key: "text",
                                                                        title: "Text",
                                                                        type: "TextLine",
                                                                        properties: {
                                                                            required: true
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    key: "integrations",
                                    title: "Integrations Tab",
                                    properties: {
                                        fields: [
                                            {
                                                key: "items",
                                                title: "Items",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            key: "name",
                                                            title: "Name",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: true
                                                            }
                                                        },
                                                        {
                                                            key: "url",
                                                            title: "Url",
                                                            type: "TextLine"
                                                        },
                                                        {
                                                            key: "subitems",
                                                            title: "SubItems",
                                                            type: "List",
                                                            properties: {
                                                                fields: [
                                                                    {
                                                                        key: "name",
                                                                        title: "Name",
                                                                        type: "TextLine",
                                                                        properties: {
                                                                            required: true
                                                                        }
                                                                    },
                                                                    {
                                                                        key: "url",
                                                                        title: "Url",
                                                                        type: "TextLine"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    key: "imagesSlider",
                                    title: "Images Slider",
                                    properties: {
                                        fields: [
                                            {
                                                key: "items",
                                                title: "Items",
                                                type: "List",
                                                properties: {
                                                    fields: [
                                                        {
                                                            key: "title",
                                                            title: "Title",
                                                            type: "TextLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            key: "description",
                                                            title: "Description",
                                                            type: "TextMultiLine",
                                                            properties: {
                                                                required: false
                                                            }
                                                        },
                                                        {
                                                            key: "item",
                                                            title: "Image",
                                                            type: "File",
                                                            properties: {
                                                                required: true
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }}
                    />}
                </Block>
            </Block>
            <ModalThatSlides title="Manage Settings" width="820px" isOpen={isModalMetatagsOpen} onRequestClose={() => setIsModalMetatagsOpen(false)}>
                <Block>
                    <Formik
                        enableReinitialize
                        initialValues={result}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    thumbnail: yup.object().nullable(),
                                    slug: yup.string().required(),
                                    status: yup.string().required(),
                                    areas: yup.array(yup.number().integer())
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationProductUpdateGeneric({
                                variables: {
                                    id: productId,
                                    name: values.name,
                                    thumbnail: values.thumbnail,
                                    slug: values.slug,
                                    status: values.status,
                                    areas: values.areas
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>Generic</Text>
                                        <Block mt={12}>
                                            <FileInput label="Thumbnail" name="thumbnail" onChange={handleChange} onBlur={handleBlur} value={values.thumbnail} error={errors.thumbnail} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Name" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Slug" name="slug" onChange={handleChange} onBlur={handleBlur} value={values.slug} error={errors.slug} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Status" name="status" onBlur={handleBlur} value={values.status} error={errors.status} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput isMulti label="Areas" name="areas" onBlur={handleBlur} value={values.areas.map((area) => area.id)} error={errors.areas} options={areas} />
                                        </Block>
                                        <Block mt={28}>
                                            <Button type="submit" text="Save" loading={loadingProductUpdateGeneric} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
                <Block mt={12}>
                    <Formik
                        initialValues={(result && result.metatags) || {}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationProductUpdateMetatags({
                                variables: {
                                    id: productId,
                                    metatags: values
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>Metatags</Text>
                                        <Block mt={12}>
                                            <TextInput label="Title" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} error={errors.title} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput label="Description" name="description" onChange={handleChange} onBlur={handleBlur} value={values.description} error={errors.description} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Keywords" name="keywords" onBlur={handleBlur} value={values.keywords} error={errors.keywords} isMulti />
                                        </Block>
                                        <Block mt={28}>
                                            <Button type="submit" text="Save" loading={loadingProductUpdateMetatags} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </ModalThatSlides>
        </>
    );
}

export default ProductEdit;
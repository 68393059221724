import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Header, Modal, ModalThatSlides, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, SelectInput, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconCirclePlus, SvgIconDown, SvgIconEdit, SvgIconModalDelete, SvgIconTrash, SvgIconUp } from '../../svgs';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';
import SubRow from './components/SubRow';

function Clients() {
    const { language } = useContext(store);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");

    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalUpdateData, setModalUpdateData] = useState(null);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);

    const typeCreateFormRef = useRef(null);
    const [typeFilter, setTypeFilter] = useState(null);
    const [types, setTypes] = useState([]);
    const [isModalTypesOpen, setIsModalTypesOpen] = useState(false);
    const [modalTypeUpdateIsOpen, setModalTypeUpdateIsOpen] = useState(null);
    const [modalTypeDeleteIsOpen, setModalTypeDeleteIsOpen] = useState(null);

    const [modalCreateFreguesiaIsOpen, setModalCreateFreguesiaIsOpen] = useState(false);
    const [modalUpdateFreguesiaData, setModalUpdateFreguesiaData] = useState(null);
    const [modalDeleteFreguesiaIsOpen, setModalDeleteFreguesiaIsOpen] = useState(null);

    const [rowsOpened, setRowsOpened] = useState([]);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const countryOptions = [
        { label: "Portugal", value: "PORTUGAL" },
        { label: "Angola", value: "ANGOLA" },
        { label: "Cabo Verde", value: "CABO_VERDE" },
        { label: "Guiné-Bissau", value: "GUINE_BISSAU" },
    ];

    const regionOptions = [
        { label: "Norte", value: "NORTE" },
        { label: "Centro", value: "CENTRO" },
        { label: "Sul", value: "SUL" },
        { label: "Região Autónoma da Madeira", value: "MADEIRA" },
        { label: "Região Autónoma dos Açores", value: "ACORES" },
    ];

    const [queryClients, { loading }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String, $type: ID) {
                clients(first: $first, after: $after, search: $search, type: $type) {
                    edges {
                        node {
                            id
                            name
                            type {
                                id
                                name
                            }
                            country
                            region
                            freguesias {
                                id
                                name
                                parentId
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clients) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.clients.edges] : data.clients.edges,
                        pageInfo: data.clients.pageInfo,
                        totalCount: data.clients.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );


    const [mutationClientCreate, { loading: loadingClientCreate }] = useMutation(
        gql`
            mutation clientCreate($name: String!, $type: String!, $country: String!, $region: String) {
                clientCreate(name: $name, type: $type, country: $country, region: $region) {
                    id
                    name
                    type {
                        id
                        name
                    }
                    country
                    region
                    freguesias {
                        id
                        name
                        parentId
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                requestData();
                setModalCreateIsOpen(false);
            }
        }
    );

    const [mutationClientUpdate, { loading: loadingClientUpdate }] = useMutation(
        gql`
                mutation clientUpdate($id: ID!, $name: String, $type: String, $country: String, $region: String) {
                    clientUpdate(id: $id, name: $name, type: $type, country: $country, region: $region) {
                        id
                        name
                        type {
                            id
                            name
                        }
                        country
                        region
                        freguesias {
                            id
                            name
                            parentId
                        }
                    }
                }
            `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientUpdate) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.clientUpdate.id);
                    if (index !== -1) {
                        result.edges[index].node = { ...data.clientUpdate };
                        setModalUpdateData(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [mutationClientDelete, { loading: loadingClientDelete }] = useMutation(
        gql`
            mutation clientDelete($id: ID!) {
                clientDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.clientDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [mutationClientFreguesiaCreate, { loading: loadingClientFreguesiaCreate }] = useMutation(
        gql`
            mutation clientCreateFreguesia($name: String!, $parent: ID!) {
                clientCreateFreguesia(name: $name, parent: $parent) {
                    id
                    name
                    parentId
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientCreateFreguesia) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.clientCreateFreguesia.parentId);
                    if (index !== -1) {
                        result.edges[index].node.freguesias.unshift({ ...data.clientCreateFreguesia });
                        setModalCreateFreguesiaIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const [mutationClientFreguesiaUpdate, { loading: loadingClientFreguesiaUpdate }] = useMutation(
        gql`
                mutation clientUpdateFreguesia($id: ID!, $name: String) {
                    clientUpdateFreguesia(id: $id, name: $name) {
                        id
                        name
                        parentId
                    }
                }
            `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientUpdateFreguesia) {
                    setModalUpdateFreguesiaData(null);
                    const index = result.edges.findIndex((edge) => edge.cursor == data.clientUpdateFreguesia.parentId);
                    if (index !== -1) {
                        const indexFreguesias = result.edges[index].node.freguesias.findIndex((freguesia) => freguesia.id == data.clientUpdateFreguesia.id);
                        if (indexFreguesias !== -1) {
                            result.edges[index].node.freguesias[indexFreguesias] = { ...data.clientUpdateFreguesia };
                            setResult([...data]);
                        }
                    }
                }
            }
        }
    );

    const [mutationClientFreguesiaDelete, { loading: loadingClientFreguesiaDelete }] = useMutation(
        gql`
            mutation clientDeleteFreguesia($id: ID!) {
                clientDeleteFreguesia(id: $id) {
                    id
                    parentId
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientDeleteFreguesia) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.clientDeleteFreguesia.parentId);
                    if (index !== -1) {
                        const indexFreguesias = result.edges[index].node.freguesias.findIndex((freguesia) => freguesia.id == data.clientDeleteFreguesia.id);
                        if (indexFreguesias !== -1) {
                            result.edges[index].node.freguesias.splice(indexFreguesias, 1);
                            setModalDeleteFreguesiaIsOpen(null);
                            setResult([...data]);
                        }
                    }
                }
            }
        }
    );

    const [queryTypes, { loading: loadingTypes }] = useLazyQuery(
        gql`
            query {
                types {
                    id
                    name
                    position
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.types) {
                    setTypes(data.types);
                }
            }
        }
    );

    const [mutationTypeCreate, { loading: loadingTypeCreate }] = useMutation(
        gql`
            mutation typeCreate($name: String!) {
                typeCreate(name: $name) {
                    id
                    name
                    position
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.typeCreate) {
                    typeCreateFormRef.current.reset();
                    queryTypes();
                }
            }
        }
    );

    const [mutationTypeUpdate, { loading: loadingTypeUpdate }] = useMutation(
        gql`
            mutation typeUpdate($id: ID!, $name: String, $position: Int) {
                typeUpdate(id: $id, name: $name, position: $position) {
                    id
                    name
                    position
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.typeUpdate) {
                    setModalTypeUpdateIsOpen(null);
                    queryTypes();
                }
            }
        }
    );

    const [mutationTypeDelete, { loading: loadingTypeDelete }] = useMutation(
        gql`
            mutation typeDelete($id: ID!) {
                typeDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.typeDelete) {
                    const index = types.findIndex((type) => type.id === data.typeDelete.id);
                    if (index !== -1) {
                        types.splice(index, 1);
                        setModalTypeDeleteIsOpen(null);
                        setTypes([...types]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryClients({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined,
                type: typeFilter !== "" ? typeFilter : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
        queryTypes();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search, typeFilter]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={
                        <Block row>
                            <Block pointer row middle center pl={48} pr={48} color="white">
                                <select onChange={(e) => setTypeFilter(e.target.value)} style={{ border: "none" }}>
                                    <option value="">Select a type</option>
                                    {types.map((type) => <option value={type.id}>{type.name}</option>)}
                                </select>
                            </Block>
                            <Block pointer row middle center pl={48} pr={48} color="greyLightest" onClick={() => setIsModalTypesOpen(true)}>
                                <Text size={14} height={28}>Manage types</Text>
                            </Block>
                            <Button style={{ minWidth: 309 }} text="Add Client" onClick={() => setModalCreateIsOpen(true)} />
                        </Block>
                    }
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        customActionsWidth={487}
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Type", key: "type", options: { width: 300 } },
                            { label: "Country", key: "country", options: { width: 100 } },
                            { label: "Region", key: "region", options: { width: 100 } }
                        ]}
                        rows={result.edges.map((edge) => {
                            const rowOpenedIndex = rowsOpened.findIndex((rowOpened) => rowOpened == edge.cursor);

                            return {
                                name: edge.node.name,
                                type: edge.node.type.name,
                                country: edge.node.country && countryOptions.find((country) => country.value === edge.node.country).label,
                                region: edge.node.region && regionOptions.find((region) => region.value === edge.node.region).label,
                                subRowsOpen: !!rowsOpened.find((rowOpened) => rowOpened == edge.cursor),
                                subRows: edge.node.freguesias && edge.node.freguesias.map((client) => (
                                    <SubRow
                                        key={client.id}
                                        title={client.name}
                                        actions={<>
                                            <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdateFreguesiaData(client)} />
                                            <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteFreguesiaIsOpen(client)} />
                                        </>}
                                    />
                                )),
                                actions: <>
                                    <ButtonAction icon={<SvgIconCirclePlus />} text="Add category" onClick={() => setModalCreateFreguesiaIsOpen(edge.cursor)} />
                                    {(edge.node.freguesias && edge.node.freguesias.length > 0) && <ButtonAction icon={rowOpenedIndex === -1 ? <SvgIconDown /> : <SvgIconUp />} text="Categories" onClick={() => {
                                        if (rowOpenedIndex === -1) {
                                            rowsOpened.push(edge.cursor);
                                        } else {
                                            rowsOpened.splice(rowOpenedIndex, 1);
                                        }
                                        setRowsOpened([...rowsOpened]);
                                    }} />}
                                    <ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalUpdateData({ ...edge.node, type: edge.node.type.id })} />
                                    <ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} />
                                </>
                            }
                        })}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create Client"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Client</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    type: yup.string().required(),
                                    country: yup.string().required(),
                                    region: yup.string().nullable().when('country', {
                                        is: (country) => country === "PORTUGAL",
                                        then: yup.string()
                                            .required()
                                    })
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationClientCreate({ variables: { ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autocomplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput showAll name="type" label="Type" onChange={handleChange} onBlur={handleBlur} error={errors.type} options={types.map((type) => ({ value: type.id, label: type.name }))} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput showAll name="country" label="Country" onChange={handleChange} onBlur={handleBlur} error={errors.country} options={countryOptions} />
                                            </Block>
                                            {(!!values.country && values.country === "PORTUGAL") && <Block mt={12}>
                                                <SelectInput showAll name="region" label="Region" onChange={handleChange} onBlur={handleBlur} error={errors.region} options={regionOptions} />
                                            </Block>}
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingClientCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                title="Update Client"
                isOpen={!!modalUpdateData}
                onRequestClose={() => setModalUpdateData(null)}
            >
                <Text size={14} height={20}>Client</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdateData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    type: yup.string().required(),
                                    country: yup.string().required(),
                                    region: yup.string().nullable().when('country', {
                                        is: (country) => country === "PORTUGAL",
                                        then: yup.string()
                                            .required()
                                    })
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            if (values.country !== "PORTUGAL") values.region = null;
                            mutationClientUpdate({ variables: { id: modalUpdateData.id, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autocomplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput showAll name="type" label="Type" onChange={handleChange} onBlur={handleBlur} error={errors.type} options={types.map((type) => ({ value: type.id, label: type.name }))} />
                                            </Block>
                                            <Block mt={12}>
                                                <SelectInput showAll name="country" label="Country" onChange={handleChange} onBlur={handleBlur} error={errors.country} options={countryOptions} />
                                            </Block>
                                            {(!!values.country && values.country === "PORTUGAL") && <Block mt={12}>
                                                <SelectInput showAll name="region" label="Region" onChange={handleChange} onBlur={handleBlur} error={errors.region} options={regionOptions} />
                                            </Block>}
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingClientUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Client"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this client?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this client.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingClientDelete} onClick={() => {
                        if (modalDeleteIsOpen.includes("sub")) {
                            const id = modalDeleteIsOpen.split("sub")[1];
                            mutationClientDelete({ variables: { id } });
                        } else {
                            mutationClientDelete({ variables: { id: modalDeleteIsOpen } });
                        }
                    }} />
                </Block>
            </Modal>
            <Modal
                title="Create Category"
                isOpen={!!modalCreateFreguesiaIsOpen}
                onRequestClose={() => setModalCreateFreguesiaIsOpen(null)}
            >
                <Text size={14} height={20}>Category</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationClientFreguesiaCreate({ variables: { parent: modalCreateFreguesiaIsOpen, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autocomplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingClientFreguesiaCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                title="Update Category"
                isOpen={!!modalUpdateFreguesiaData}
                onRequestClose={() => setModalUpdateFreguesiaData(null)}
            >
                <Text size={14} height={20}>Category</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalUpdateFreguesiaData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationClientFreguesiaUpdate({ variables: { id: modalUpdateFreguesiaData.id, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autocomplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingClientFreguesiaUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Category"
                isOpen={!!modalDeleteFreguesiaIsOpen}
                onRequestClose={() => setModalDeleteFreguesiaIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this category?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this category.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingClientFreguesiaDelete} onClick={() => {
                        mutationClientFreguesiaDelete({ variables: { id: modalDeleteFreguesiaIsOpen.id } });
                    }} />
                </Block>
            </Modal>

            <ModalThatSlides title="Manage Types" width="820px" isOpen={isModalTypesOpen} onRequestClose={() => setIsModalTypesOpen(false)}>
                <Block>
                    <Formik
                        enableReinitialize
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationTypeCreate({
                                variables: {
                                    name: values.name
                                }
                            })
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form ref={typeCreateFormRef} onSubmit={handleSubmit} autocomplete="off">
                                    <Block flex color="white" pt={16} pb={16} pl={24} pr={24}>
                                        <Text size={14} height={20} mb={4}>New Type</Text>
                                        <Block row mt={12}>
                                            <TextInput label="Name" name="name" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                            <Button type="submit" text="Submit" loading={loadingTypeCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
                <Block>
                    <Table
                        loading={loadingTypes}
                        columns={[
                            { label: "Name", key: "name" },
                        ]}
                        rows={types.map((type) => ({ ...type, actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalTypeUpdateIsOpen(type)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalTypeDeleteIsOpen(type.id)} /></> }))}
                        hasMore={false}
                    />
                </Block>
            </ModalThatSlides>
            <Modal
                title="Update Type"
                isOpen={!!modalTypeUpdateIsOpen}
                onRequestClose={() => setModalTypeUpdateIsOpen(null)}
            >
                <Text size={14} height={20}>Type</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={modalTypeUpdateIsOpen}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    position: yup.number().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationTypeUpdate({ variables: { id: modalTypeUpdateIsOpen.id, name: values.name, position: values.position } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autocomplete="off">
                                    <Block>
                                        <Block>
                                            <Block>
                                                <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} error={errors.name} />
                                            </Block>
                                            <Block  mt={12}>
                                                <TextInput name="position" label="Position" type="number" onChange={handleChange} onBlur={handleBlur} value={values.position} error={errors.position} />
                                            </Block>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingTypeUpdate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete Type"
                isOpen={!!modalTypeDeleteIsOpen}
                onRequestClose={() => setModalTypeDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this type?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this type.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingTypeDelete} onClick={() => mutationTypeDelete({ variables: { id: modalTypeDeleteIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Clients;
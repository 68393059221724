import React from "react";

function IconCaseStudies({ color = "#5D6368", ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
            {...props}
        >
            <path stroke={color} d="M11 12v2H2V3h9v3.667"></path>
            <circle cx="10.5" cy="9.5" r="2.5" stroke={color}></circle>
            <path stroke={color} d="M12 11l3 3"></path>
        </svg>
    );
}

export default IconCaseStudies;

import React, { useEffect, useRef, useState } from "react";
import styled, { css } from 'styled-components';
import colors from "../../../utils/colors";
import Block from '../Block';
import Text from '../Text';

function TextInput(
    {
        name,
        label,
        value,
        error,
        onChange,
        onBlur,
        ...props
    }
) {
    const inputRef = useRef(null);
    const [innerValue, setInnerValue] = useState(value || "");
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setInnerValue(value);
    }, [value]);

    return (
        <BlockInput>
            <TextComponent
                move={(isFocused || innerValue !== '') ? "true" : undefined}
                size={(isFocused || innerValue !== '') ? 10 : 14}
                height={(isFocused || innerValue !== '') ? 12 : 24}
                color="greyLight"
                onClick={() => inputRef.current.focus()}
            >
                {label}
            </TextComponent>
            <Input
                value={value}
                name={name}
                type="text"
                ref={inputRef}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => { onBlur && onBlur(e); setIsFocused(false); }}
                onChange={(e) => {
                    onChange && onChange(e);
                    setInnerValue(e.target.value);
                }}
                error={error}
                {...props}
            />
            {error &&
                <Block mt={4}>
                    <Text
                        size={10}
                        height={12}
                        color="greyDark"
                    >
                        {error}
                    </Text>
                </Block>
            }
        </BlockInput>
    );
}

export default React.memo(TextInput);

const BlockInput = styled(Block)`
    position: relative;
    width: 100%;
`;

const Input = styled.input`
    background-color: ${colors.greyNotWhite};
    height: 52px;
    color: ${colors.greyDark};
    font-size: 14px;
    line-height: 24px;
    font-family: "Medium";
    border: 0;
    padding: 20px 16px 8px;
    transition: all 0.4s ease-in-out;
    border-bottom: 1px solid #B2B6B9;
    border-radius: 0;
    :focus{
        border-color: ${colors.greyDark};
    }
    ${({ error }) => error && css`
        border-color: #FF0505;
        color: #FF0505;
    `}
`;

const TextComponent = styled(Text)`
    position: absolute;
    top: 14px;
    left: 16px;
    transition: all 0.2s ease-in-out;
    ${({ move }) => move === "true" && css`
        transform: translateY(-6px);
    `}
`;

import * as React from "react"

function LogoWhite(props) {
    return (
        <svg
            width={84}
            height={24}
            viewBox="0 0 84 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M37.717 22.233s-.06 1.044.683 1.466c.442.281 1.064.261 1.064.261v-5.362h-1.747v3.635zM49.607 17.934H45.95c-2.35 0-2.35 1.707-2.35 1.707v2.792s.08 1.526 1.668 1.547h4.318c1.405 0 1.606-1.688 1.606-1.688v-5.804h-1.606v1.446h.02zm0 4.358c0 .422-.523.442-.523.442H45.73s-.502-.04-.502-.482v-2.63c0-.503.743-.463.743-.463h3.636v3.134zM31.571 17.934h-3.655c-2.35 0-2.35 1.707-2.35 1.707v2.792s.08 1.526 1.667 1.547H31.551c1.406 0 1.607-1.688 1.607-1.688v-5.804h-1.607v1.446h.02zm0 4.358c0 .422-.522.442-.522.442h-3.354s-.502-.04-.502-.482v-2.63c0-.503.743-.463.743-.463h3.655v3.134h-.02zM39.464 16.488h-1.747v1.165h1.747v-1.165zM71.116 22.795h-1.225l-.04.06.04-.06s-.603.08-.603-.744v-1.968h1.507V19.08h-1.507v-2.61h-1.606v5.823c0 .382.22 1.688 1.426 1.688l3.113.02c.04-.603-.563-1.205-1.105-1.205zM15.665 23.98h5.383c0-.603-.583-1.185-1.125-1.205h-3.454c-.563 0-.603-.723-.603-.723l-1.767.502c0 1.044.883 1.426 1.566 1.426z"
                fill="#fff"
            />
            <path
                d="M20.485 18.015h-4.96s-1.426.12-1.426 1.325v3.23l1.767-.478v-.603h4.479s1.566-.1 1.566-1.727c0-1.647-1.426-1.747-1.426-1.747zm-.924 2.55h-3.695v-.964s-.06-.683.723-.683h2.832c.803 0 .763.824.763.824s.18.823-.623.823zM61.797 18.015h-5.362c0 .602.582 1.185 1.125 1.205h3.454c.562 0 .602.723.602.723v.562h-4.478s-1.567.1-1.567 1.727 1.426 1.748 1.426 1.748h4.94s1.427-.121 1.427-1.326v-3.193c0-1.065-.884-1.446-1.567-1.446zm-.924 5.04h-2.831c-.804 0-.764-.823-.764-.823s-.18-.803.623-.803h3.695v.964s.06.663-.723.663zM81.84 17.995h-5.382c0 .602.583 1.185 1.125 1.205h3.454c.563 0 .603.723.603.723v.562H77.16s-1.566.1-1.566 1.727 1.426 1.747 1.426 1.747h4.94s1.426-.12 1.426-1.325v-3.193c.02-1.065-.863-1.446-1.546-1.446zm-.943 5.06h-2.832c-.803 0-.763-.823-.763-.823s-.18-.803.622-.803h3.696v.964c.02-.02.08.663-.723.663zM8.395 18.015h-7.03S.04 18.075 0 19.62v4.359h1.687V19.62s-.04-.542.482-.542h1.888v4.9h1.687v-4.9h1.888c.522 0 .482.542.482.542v4.359H9.8V19.62c-.08-1.546-1.406-1.606-1.406-1.606z"
                fill="#fff"
            />
            <path
                d="M49.446 0v6.949l2.812-3.475L49.446 0zM46.434 2.69H43.44V6.95h2.993V2.69zM34.263 0v6.949L31.45 3.474 34.263 0zM37.277 6.954h2.992V2.696h-2.992v4.258z"
                fill="#368D67"
            />
        </svg>
    )
}

export default LogoWhite

import * as React from "react"

function Logo(props) {
    return (
        <svg
            width={98}
            height={28}
            viewBox="0 0 98 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M44.055 25.939s-.07 1.218.797 1.71c.515.328 1.242.305 1.242.305v-6.256h-2.039v4.24zM57.926 20.924H53.66c-2.741 0-2.741 1.992-2.741 1.992v3.256s.094 1.781 1.945 1.805h5.038c1.64 0 1.874-1.968 1.874-1.968v-6.772h-1.875v1.687h.024zm0 5.085c0 .491-.61.515-.61.515h-3.912s-.586-.047-.586-.562v-3.07c0-.586.867-.539.867-.539h4.24v3.655zM36.885 20.924H32.62c-2.741 0-2.741 1.992-2.741 1.992v3.256s.094 1.781 1.945 1.805h5.038c1.64 0 1.874-1.968 1.874-1.968v-6.772H36.86v1.687h.024zm0 5.085c0 .491-.61.515-.61.515h-3.912s-.586-.047-.586-.562v-3.07c0-.586.867-.539.867-.539h4.264v3.655h-.023zM46.093 19.237h-2.038v1.359h2.038v-1.36zM83.02 26.594h-1.429l-.047.07.047-.07s-.703.094-.703-.867v-2.296h1.757v-1.172h-1.757v-3.046h-1.874v6.796c0 .445.257 1.968 1.663 1.968L84.31 28c.047-.703-.656-1.406-1.289-1.406zM18.327 27.977h6.28c0-.703-.68-1.383-1.313-1.406h-4.03c-.656 0-.703-.844-.703-.844l-2.062.586c0 1.219 1.031 1.664 1.828 1.664z"
                fill="#5D6368"
            />
            <path
                d="M23.95 21.018h-5.787s-1.664.14-1.664 1.546v3.768l2.062-.558v-.703h5.225s1.828-.117 1.828-2.015c0-1.921-1.664-2.038-1.664-2.038zm-1.078 2.975h-4.311V22.87s-.07-.797.843-.797h3.304c.938 0 .89.96.89.96s.212.961-.726.961zM72.148 21.018h-6.256c0 .703.68 1.382 1.313 1.405h4.03c.656 0 .703.844.703.844v.656h-5.226s-1.827.117-1.827 2.015c0 1.898 1.663 2.039 1.663 2.039h5.764s1.664-.14 1.664-1.547v-3.725c0-1.242-1.03-1.687-1.828-1.687zm-1.077 5.88h-3.304c-.937 0-.89-.96-.89-.96s-.212-.937.726-.937h4.311v1.125s.07.773-.843.773zM95.533 20.994h-6.28c0 .703.68 1.383 1.313 1.406h4.03c.656 0 .703.844.703.844v.656h-5.225s-1.828.117-1.828 2.015c0 1.898 1.664 2.038 1.664 2.038h5.764s1.663-.14 1.663-1.546V22.68c.024-1.242-1.007-1.687-1.804-1.687zM94.432 26.9h-3.304c-.937 0-.89-.96-.89-.96s-.211-.938.726-.938h4.311v1.125c.024-.024.094.773-.843.773zM9.845 21.018h-8.2s-1.547.07-1.594 1.874v5.085h1.968v-5.085s-.047-.633.562-.633h2.203v5.718h1.968v-5.718h2.203c.609 0 .562.633.562.633v5.085h1.968v-5.085c-.093-1.804-1.64-1.874-1.64-1.874z"
                fill="#5D6368"
            />
            <path
                d="M57.74 0v8.107l3.28-4.053L57.74 0zM54.225 3.14h-3.492v4.967h3.492V3.14zM40.025 0v8.107l-3.28-4.053L40.024 0zM43.541 8.113h3.491V3.146h-3.491v4.967z"
                fill="#368D67"
            />
        </svg>
    )
}

export default Logo
